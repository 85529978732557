import {
  AT_HOME_SITUATION,
  HOME_CARE_SITUATION,
  LIVING_SITUATION_ALONE,
  LIVING_SITUATION_CAREHOME,
  LIVING_SITUATION_IN_ELDERLY_HOME,
  LIVING_SITUATION_SHARED_FLAT,
  LIVING_SITUATION_WITH_FAMILY,
  REHAB_FORM_NAMES,
} from "core/consts";
import { getName } from "core/model/accounts";
import { Auction, GetOntologyType, PredicamentState } from "core/types";
import Translations from "translations/types";
import { RehabFormFormGetters } from "../../configs";
import {
  auctionBirthdatetoUnix,
  booleanToPredicamentState,
  getPatientStreetHouseNumber,
} from "../../utils";

export const importAuctionToGeneralUniversalForm = (
  getOntology: GetOntologyType,
  _translations: Translations,
): RehabFormFormGetters<typeof REHAB_FORM_NAMES.GKV_GENERAL> => ({
  patient_name: (auction: Auction) =>
    [
      auction?.patient?.profile?.last_name?.decrypted,
      auction?.patient?.profile?.first_name?.decrypted,
    ]
      .truthy()
      .join(", "),
  patient_birthdate: auctionBirthdatetoUnix,
  insurance_number: (auction: Auction) =>
    auction.patient.profile?.financing?.insurance_number?.decrypted ?? "",
  living_lift: (auction: Auction) =>
    auction.patient.profile?.lift_available_state as PredicamentState,
  living_lift_description: (auction: Auction) =>
    auction.patient.profile?.lift_available_description ?? null,
  living_situation: (auction: Auction) => {
    const livingSituation = auction.patient.profile?.living_situation;

    switch (livingSituation) {
      case LIVING_SITUATION_ALONE:
        return HOME_CARE_SITUATION.HOME;
      case LIVING_SITUATION_WITH_FAMILY:
        return HOME_CARE_SITUATION.HOME;
      case LIVING_SITUATION_CAREHOME:
        return HOME_CARE_SITUATION.CARE_HOME;
      case LIVING_SITUATION_IN_ELDERLY_HOME:
      case LIVING_SITUATION_SHARED_FLAT:
        return HOME_CARE_SITUATION.ASSISTED_LIVING;
      default:
        return null;
    }
  },
  living_situation_at_home: (auction: Auction) => {
    const livingSituation = auction.patient.profile?.living_situation;

    switch (livingSituation) {
      case LIVING_SITUATION_ALONE:
        return AT_HOME_SITUATION.ALONE;
      case LIVING_SITUATION_WITH_FAMILY:
        return AT_HOME_SITUATION.RELATIVES;
      default:
        return null;
    }
  },
  domestic_situation_is_safe: (auction: Auction) =>
    auction.patient.profile?.unsafe_current_domestic_situation_state ?? null,
  hospital_name: (auction: Auction) => auction.patient.careseeker?.name ?? null,
  hospital_address: (auction: Auction) =>
    [
      auction.patient.careseeker?.address?.street,
      auction.patient.careseeker?.address?.street_number,
    ]
      .truthy()
      .join(" "),
  hospital_zipcode_city: (auction: Auction) =>
    [
      auction.patient.careseeker?.address?.zipcode,
      auction.patient.careseeker?.address?.city,
    ]
      .truthy()
      .join(", "),
  case_id: (auction: Auction) => auction.patient.external_id ?? null,
  social_worker_full_name: (auction: Auction) =>
    (auction.patient.social_worker &&
      getName(auction.patient.social_worker, getOntology)) ??
    null,
  social_worker_fax_number: (auction: Auction) =>
    auction.patient.social_worker?.fax_number ?? null,
  social_worker_phone_number: (auction: Auction) =>
    auction.patient.social_worker?.phone ?? null,
  insurance_company_name: (auction: Auction) =>
    auction?.patient.profile?.financing?.insurance?.name ?? null,
  patient_street_housenumber: getPatientStreetHouseNumber,
  patient_city_zipcode: (auction: Auction) =>
    [
      auction.profile?.search_location?.zipcode,
      auction.profile?.search_location?.city,
    ]
      .truthy()
      .join(", "),
  communication_in_german_possible: (auction: Auction) => {
    const cannotSpeakGerman =
      auction.patient.profile?.communication
        ?.patient_cannot_communicate_in_german;
    if (cannotSpeakGerman == null) {
      return null;
    }
    return booleanToPredicamentState(!cannotSpeakGerman);
  },
  communication_other_language: (auction: Auction) =>
    auction.patient.profile?.communication?.patient_language || null,
});
