import { REHAB_FORM_NAMES } from "core/consts";
import { getErrorMessage } from "core/model/utils/errors";
import {
  assertUnreachable,
  formatDate,
} from "dsl/ecosystems/PatientForms/utils";
import { PdfFillerProps } from "../../configs";
import { RehabFormDataMapped } from "../../utils";

export const generalUniversalFormMapping = ({
  form,
  formData,
  locale,
}: PdfFillerProps<typeof REHAB_FORM_NAMES.GKV_GENERAL>) => {
  Object.entries(formData).forEach(([key, value]) => {
    try {
      const formFieldName = key as keyof RehabFormDataMapped<
        typeof REHAB_FORM_NAMES.GKV_GENERAL
      >;
      switch (formFieldName) {
        // DATE FIELDS
        case "prefered_clinic_contacted_start_date":
        case "patient_birthdate": {
          if (!value) break;

          const date = formatDate(value as number, locale);
          form.getTextField(formFieldName).setText(date);

          break;
        }
        // TEXT FIELDS
        case "case_id":
        case "communication_other_language":
        case "general_practitioner_city":
        case "general_practitioner_name":
        case "general_practitioner_phone_number":
        case "general_practitioner_street":
        case "general_practitioner_zipcode":
        case "hospital_address":
        case "hospital_name":
        case "hospital_zipcode_city":
        case "insurance_company_name":
        case "insurance_number":
        case "insured_details_guardian_city":
        case "insured_details_guardian_last_first_name":
        case "insured_details_guardian_phone":
        case "insured_details_guardian_street_house_number":
        case "insured_details_guardian_zipcode":
        case "living_lift_description":
        case "living_special":
        case "patient_city_zipcode":
        case "patient_name":
        case "patient_phone_number":
        case "patient_street_housenumber":
        case "prefered_clinic_contacted_details":
        case "reason_for_reha_clinic":
        case "retirement_earning_capacity_company":
        case "retirement_pension_company":
        case "social_worker_fax_number":
        case "social_worker_full_name":
        case "social_worker_phone_number": {
          if (value != null && typeof value !== "string") {
            console.warn("incorrect value type for text field", {
              formFieldName,
              value,
            });
            break;
          }

          form.getTextField(formFieldName).setText(value || "");
          break;
        }
        // RADIO FIELDS
        case "retirement_pension_earning_capacity_applied":
        case "retirement_pension_applied":
        case "prefered_clinic_contacted":
        case "living_situation_at_home":
        case "living_situation":
        case "living_lift":
        case "insured_details_guardian_type":
        case "insured_details_application_insurance_benefits":
        case "domestic_situation_is_safe":
        case "communication_in_german_possible": {
          const radio = form.getRadioGroup(formFieldName);
          if (value == null) {
            radio.clear();
            return;
          }
          if (typeof value !== "number") {
            console.warn("incorrect value type for radio field", {
              formFieldName,
              value,
            });
            break;
          }

          radio.select(value.toString());
          break;
        }
        default: {
          assertUnreachable(formFieldName);
        }
      }
    } catch (err) {
      console.error(
        `error setting field ${key} in PDF: ${getErrorMessage(err)}`,
      );
    }
  });
};
