import {
  GENDER_FEMALE,
  GENDER_MALE,
  GENDER_NO_ENTRY,
  GENDER_OTHER,
  LACK_OF_CAPACITY_DOCUMENT,
  LEGAL_GUARDIAN,
  REHAB_FORM_NAMES,
  REQUESTED_REHAB_SERVICE,
  REQUESTED_REHAB_SERVICE_TYPE,
  VISUALLY_IMPAIRED_DOCUMENT,
  YES_NO_FIELD_OPTIONS,
} from "core/consts";
import {
  EmploymentStatus,
  GendersExtended,
  HealthInsuranceType,
  MaritalStatus,
  PriorEmploymentStatus,
} from "core/types";
import {
  booleanToPredicamentState,
  convertActivableInputToPredicamentState,
  OldRehabFormDataMapped,
  PENSION_INSURANCE_NUMBER_CHARACTER_LIMIT,
  stringDateToUnix,
  yesNoUnknownToPredicament,
} from "../../utils";
import {
  GeneralFormPensionData,
  GeneralFormPensionDataOld,
} from "./modelDefinition";

const setRehabServiceType = (
  dayOption?: boolean | null,
  fullOption?: boolean | null,
) => {
  if (dayOption && fullOption) return null;
  return dayOption
    ? REQUESTED_REHAB_SERVICE_TYPE.INPATIENT
    : REQUESTED_REHAB_SERVICE_TYPE.OUTPATIENT;
};

export const convertGeneralFormPensionData = ({
  formDataOld,
}: {
  formDataOld: OldRehabFormDataMapped<typeof REHAB_FORM_NAMES.DRV_GENERAL>;
}): GeneralFormPensionData => {
  if (!formDataOld) return {};

  const newData: GeneralFormPensionData = {};

  Object.keys(formDataOld).forEach((key) => {
    switch (key as keyof GeneralFormPensionDataOld) {
      case "_claim_for_damages": {
        newData.claim_for_damages_approved = yesNoUnknownToPredicament(
          formDataOld._claim_for_damages,
        );
        break;
      }

      case "_contributions_to_foreign_insurance_info": {
        newData.contributions_to_foreign_insurance_info =
          yesNoUnknownToPredicament(
            formDataOld._contributions_to_foreign_insurance_info,
          );
        break;
      }

      case "_health_damages": {
        newData.health_damage_present = yesNoUnknownToPredicament(
          formDataOld._health_damages,
        );
        break;
      }

      case "application_submitted": {
        newData.application_submitted_applicable =
          convertActivableInputToPredicamentState(
            formDataOld.application_submitted,
          );
        newData.application_submitted =
          formDataOld.application_submitted || null;
        break;
      }

      case "before_retirement_pension": {
        newData.before_retirement_pension_applicable =
          convertActivableInputToPredicamentState(
            formDataOld.before_retirement_pension,
          );
        newData.before_retirement_pension =
          formDataOld.before_retirement_pension || null;
        break;
      }

      case "claim_for_damages": {
        if (!formDataOld.claim_for_damages) break;

        newData.claim_for_damages_description = stringDateToUnix({
          date: formDataOld.claim_for_damages?.claim_for_damages_description,
        });
        newData.claim_for_damages_file_number =
          formDataOld.claim_for_damages?.claim_for_damages_file_number || null;
        newData.claim_for_damages_origin =
          formDataOld.claim_for_damages?.claim_for_damages_origin || null;
        break;
      }

      case "consent_of_the_insured": {
        if (formDataOld.consent_of_the_insured === YES_NO_FIELD_OPTIONS.YES) {
          newData.consent_of_the_insured = LACK_OF_CAPACITY_DOCUMENT.ATTACHED;
        } else if (
          formDataOld.consent_of_the_insured === YES_NO_FIELD_OPTIONS.NO
        ) {
          newData.consent_of_the_insured = LACK_OF_CAPACITY_DOCUMENT.SEND_LATER;
        } else {
          newData.consent_of_the_insured = null;
        }
        break;
      }

      case "contact_guardian": {
        newData.contact_guardian = formDataOld.contact_guardian || null;
        break;
      }

      case "contributions_to_foreign_insurance_current": {
        newData.contributions_to_foreign_insurance_current =
          yesNoUnknownToPredicament(
            formDataOld.contributions_to_foreign_insurance_current,
          );
        break;
      }

      case "contributions_to_foreign_insurance_info": {
        if (!formDataOld.contributions_to_foreign_insurance_info) break;

        newData.contributions_to_foreign_insurance_info_from_to =
          formDataOld.contributions_to_foreign_insurance_info
            ?.contributions_to_foreign_insurance_info_from_to || null;

        newData.contributions_to_foreign_insurance_info_state =
          formDataOld.contributions_to_foreign_insurance_info
            ?.contributions_to_foreign_insurance_info_state || null;

        break;
      }

      case "contributions_to_statutory_pension": {
        newData.contributions_to_statutory_pension = yesNoUnknownToPredicament(
          formDataOld.contributions_to_statutory_pension,
        );
        break;
      }

      case "doctor_in_charge_in_hospital": {
        newData.doctor_in_charge_in_hospital =
          formDataOld.doctor_in_charge_in_hospital || null;
        break;
      }

      case "doctor_in_charge_in_hospital_first_name": {
        newData.doctor_in_charge_in_hospital_first_name =
          formDataOld.doctor_in_charge_in_hospital_first_name || null;
        break;
      }

      case "doctor_in_charge_in_hospital_phone": {
        newData.doctor_in_charge_in_hospital_phone =
          formDataOld.doctor_in_charge_in_hospital_phone || null;
        break;
      }

      case "document_braille_longhand":
      case "document_braille_shorthand":
      case "document_cs":
      case "document_daisy":
      case "document_large_print": {
        const {
          document_braille_longhand,
          document_braille_shorthand,
          document_cs,
          document_daisy,
          document_large_print,
        } = formDataOld;

        const documentTypes = [
          {
            value: document_braille_longhand,
            type: VISUALLY_IMPAIRED_DOCUMENT.BRAILLE_LONG,
          },
          {
            value: document_braille_shorthand,
            type: VISUALLY_IMPAIRED_DOCUMENT.BRAILLE_SHORT,
          },
          { value: document_cs, type: VISUALLY_IMPAIRED_DOCUMENT.CD },
          { value: document_daisy, type: VISUALLY_IMPAIRED_DOCUMENT.AUDIO },
          {
            value: document_large_print,
            type: VISUALLY_IMPAIRED_DOCUMENT.LARGE_PRINT,
          },
        ];

        const filteredDocumentTypes = documentTypes
          .filter((documentType) => documentType.value)
          .map((documentType) => documentType.type);

        newData.visually_impaired_document = filteredDocumentTypes.length
          ? filteredDocumentTypes
          : null;

        break;
      }

      case "employment_status": {
        newData.employment_status =
          (formDataOld.employment_status as EmploymentStatus) || null;
        break;
      }

      case "full_reha":
      case "day_reha":
      case "full_reha_with_oncology":
      case "day_reha_with_oncology":
      case "reha_with_oncology":
      case "reha_without_oncology": {
        const {
          day_reha,
          day_reha_with_oncology,
          full_reha,
          full_reha_with_oncology,
          reha_with_oncology,
          reha_without_oncology,
        } = formDataOld;

        if (reha_with_oncology && reha_without_oncology) {
          newData.requested_rehab_service_type_insured = null;
          newData.requested_rehab_service_type_relatives = null;
          newData.requested_rehab_service = null;
          break;
        }

        if (reha_without_oncology) {
          newData.requested_rehab_service = REQUESTED_REHAB_SERVICE.INSURED;
          newData.requested_rehab_service_type_insured = setRehabServiceType(
            day_reha_with_oncology,
            full_reha_with_oncology,
          );
        } else if (reha_with_oncology) {
          newData.requested_rehab_service = REQUESTED_REHAB_SERVICE.RELATIVES;
          newData.requested_rehab_service_type_relatives = setRehabServiceType(
            day_reha,
            full_reha,
          );
        }

        break;
      }

      case "gender_extended": {
        const genderExtendedMap: Record<number, GendersExtended> = {
          1: GENDER_MALE,
          2: GENDER_FEMALE,
          4: GENDER_OTHER,
          3: GENDER_NO_ENTRY,
        };

        newData.gender_extended = formDataOld.gender_extended
          ? (genderExtendedMap[formDataOld.gender_extended] as GendersExtended)
          : null;
        break;
      }

      case "health_damages": {
        if (!formDataOld.health_damages) break;

        newData.health_damage_file_number =
          formDataOld.health_damages.health_damage_file_number || null;
        newData.health_damage_origin =
          formDataOld.health_damages.health_damage_origin || null;
        newData.health_damages_description =
          formDataOld.health_damages.health_damages_description || null;
        break;
      }

      case "hospital_name_address": {
        newData.hospital_name_address =
          formDataOld.hospital_name_address || null;
        break;
      }

      case "insurance_company_name_address": {
        newData.insurance_company_name_address =
          formDataOld.insurance_company_name_address || null;
        break;
      }

      case "insurance_type": {
        newData.insurance_type =
          (formDataOld.insurance_type as HealthInsuranceType) || null;
        break;
      }

      case "is_legal_guardian_authorized":
      case "is_legal_guardian_custodian":
      case "is_legal_guardian_statutory_representative":
      case "is_legal_guardian_supervisor": {
        const {
          is_legal_guardian_authorized,
          is_legal_guardian_custodian,
          is_legal_guardian_statutory_representative,
          is_legal_guardian_supervisor,
        } = formDataOld;

        const legalGuardian = [
          {
            value: is_legal_guardian_authorized,
            type: LEGAL_GUARDIAN.AUTHORIZED,
          },
          {
            value: is_legal_guardian_custodian,
            type: LEGAL_GUARDIAN.CUSTODIAN,
          },
          {
            value: is_legal_guardian_statutory_representative,
            type: LEGAL_GUARDIAN.STATUTORY_REP,
          },
          {
            value: is_legal_guardian_supervisor,
            type: LEGAL_GUARDIAN.SUPERVISOR,
          },
        ];

        const selectedLegalGuardian = legalGuardian
          .filter((guardian) => guardian.value)
          .map((guardian) => guardian.type);

        if (selectedLegalGuardian.length > 1) {
          newData.legal_guardian_type = null;
        } else if (selectedLegalGuardian.length === 1) {
          newData.legal_guardian_type = selectedLegalGuardian[0];
        } else {
          newData.legal_guardian_type = null;
        }

        break;
      }

      case "last_employment": {
        newData.last_employment = formDataOld.last_employment || null;
        break;
      }

      case "legal_guardian_email": {
        newData.legal_guardian_email = formDataOld.legal_guardian_email || null;
        break;
      }

      case "legal_guardian_house_number": {
        newData.legal_guardian_house_number =
          formDataOld.legal_guardian_house_number || null;
        break;
      }

      case "legal_guardian_phone": {
        newData.legal_guardian_phone = formDataOld.legal_guardian_phone || null;
        break;
      }

      case "legal_guardian_town": {
        newData.legal_guardian_town = formDataOld.legal_guardian_town || null;
        break;
      }

      case "legal_guardian_zip_code": {
        newData.legal_guardian_zip_code =
          formDataOld.legal_guardian_zip_code || null;
        break;
      }

      case "marital_status": {
        newData.marital_status =
          (formDataOld.marital_status as MaritalStatus) || null;
        break;
      }

      case "needs_disability_equipment": {
        newData.needs_disability_equipment_applicable =
          convertActivableInputToPredicamentState(
            formDataOld.needs_disability_equipment,
          );
        newData.needs_disability_equipment =
          formDataOld.needs_disability_equipment || null;
        break;
      }

      case "patient_bank_account_holder": {
        newData.patient_bank_account_holder =
          formDataOld.patient_bank_account_holder || null;
        break;
      }

      case "patient_bank_iban": {
        newData.patient_bank_iban = formDataOld.patient_bank_iban || null;
        break;
      }

      case "patient_bank_name": {
        newData.patient_bank_name = formDataOld.patient_bank_name || null;
        break;
      }

      case "patient_birth_name": {
        newData.patient_birth_name = formDataOld.patient_birth_name || null;
        break;
      }

      case "patient_birthdate": {
        newData.patient_birthdate = stringDateToUnix({
          date: formDataOld.patient_birthdate,
        });
        break;
      }

      case "patient_city": {
        newData.patient_city = formDataOld.patient_city || null;
        break;
      }

      case "patient_first_name": {
        newData.patient_first_name = formDataOld.patient_first_name || null;
        break;
      }

      case "patient_last_name": {
        newData.patient_last_name = formDataOld.patient_last_name || null;
        break;
      }

      case "patient_maiden_name": {
        newData.patient_maiden_name = formDataOld.patient_maiden_name || null;
        break;
      }

      case "patient_nationality": {
        newData.patient_nationality = formDataOld.patient_nationality || null;
        break;
      }

      case "patient_phone_number": {
        newData.patient_phone_number = formDataOld.patient_phone_number || null;
        break;
      }

      case "patient_street_housenumber": {
        newData.patient_street_housenumber =
          formDataOld.patient_street_housenumber || null;
        break;
      }

      case "patient_unable_to_work_before_hospitalization": {
        newData.patient_unable_to_work_before_hospitalization =
          convertActivableInputToPredicamentState(
            formDataOld.patient_unable_to_work_before_hospitalization,
          );
        if (
          typeof formDataOld.patient_unable_to_work_before_hospitalization ===
          "string"
        ) {
          newData.patient_unable_to_work_before_hospitalization_date =
            stringDateToUnix({
              date: formDataOld.patient_unable_to_work_before_hospitalization,
            });
        }
        break;
      }

      case "patient_zipcode": {
        newData.patient_zipcode = formDataOld.patient_zipcode || null;
        break;
      }

      case "pension_insurance_company_name": {
        newData.pension_insurance_company_name =
          formDataOld.pension_insurance_company_name || null;
        break;
      }

      case "postal_code_doctor": {
        newData.postal_code_doctor = formDataOld.postal_code_doctor || null;
        break;
      }

      case "preferred_rehab_clinic": {
        newData.preferred_rehab_clinic =
          formDataOld.preferred_rehab_clinic || null;
        break;
      }

      case "prior_employment_status": {
        const { prior_employment_status } = formDataOld;
        if (!prior_employment_status) {
          newData.prior_employment_status = null;
          break;
        }

        const selectedPriorEmploymentStatus = prior_employment_status?.filter(
          (status) => Boolean(status),
        );

        if (selectedPriorEmploymentStatus.length > 1) {
          newData.prior_employment_status = null;
        } else {
          newData.prior_employment_status =
            selectedPriorEmploymentStatus[0] as PriorEmploymentStatus;
        }
        break;
      }

      case "retirement_pension_received": {
        newData.retirement_pension_received_applicable =
          convertActivableInputToPredicamentState(
            formDataOld.retirement_pension_received,
          );
        newData.retirement_pension_received =
          formDataOld.retirement_pension_received || null;
        break;
      }

      case "risk_of_incapacity_caused_by_accident_third_person": {
        newData.risk_of_incapacity_caused_by_accident_third_person =
          yesNoUnknownToPredicament(
            formDataOld.risk_of_incapacity_caused_by_accident_third_person,
          );
        break;
      }

      case "send_documents_per_email": {
        newData.send_documents_per_email =
          formDataOld.send_documents_per_email || null;
        break;
      }

      case "social_worker_name_number": {
        newData.social_worker_name_number =
          formDataOld.social_worker_name_number || null;
        break;
      }

      case "special_payments_from_civil_services": {
        newData.special_payments_from_civil_services =
          yesNoUnknownToPredicament(
            formDataOld.special_payments_from_civil_services,
          );
        break;
      }

      case "statutory_pension_insurance_number": {
        newData.statutory_pension_insurance_number =
          formDataOld.statutory_pension_insurance_number?.slice(
            0,
            PENSION_INSURANCE_NUMBER_CHARACTER_LIMIT,
          ) || null;
        break;
      }

      case "street_house_number_doctor": {
        newData.street_house_number_doctor =
          formDataOld.street_house_number_doctor || null;
        break;
      }

      case "structured_treatment_plan": {
        newData.structured_treatment_plan =
          formDataOld.structured_treatment_plan || null;
        break;
      }

      case "third_party_request": {
        newData.third_party_request = booleanToPredicamentState(
          !!(
            formDataOld.third_party_request === YES_NO_FIELD_OPTIONS.YES ||
            formDataOld.contact_guardian?.length ||
            formDataOld.is_legal_guardian_statutory_representative ||
            formDataOld.is_legal_guardian_custodian ||
            formDataOld.is_legal_guardian_supervisor ||
            formDataOld.is_legal_guardian_authorized ||
            formDataOld.legal_guardian_house_number?.length ||
            formDataOld.legal_guardian_phone?.length ||
            formDataOld.legal_guardian_zip_code?.length ||
            formDataOld.legal_guardian_town?.length ||
            formDataOld.legal_guardian_email?.length ||
            formDataOld.consent_of_the_insured === YES_NO_FIELD_OPTIONS.YES
          ),
        );
        break;
      }

      case "town_doctor": {
        newData.town_doctor = formDataOld.town_doctor || null;
        break;
      }

      case "unemployment_benefit_type_2": {
        newData.unemployment_benefit_type_2 = yesNoUnknownToPredicament(
          formDataOld.unemployment_benefit_type_2,
        );
        break;
      }

      default:
        break;
    }
  });

  return newData as GeneralFormPensionData;
};
