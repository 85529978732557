import { FUNCTION_DIAGNOSIS_PRIORITIES } from "core/consts";
import { SelectOption } from "ds_legacy/components/SelectInput";
import { VerticalLayout } from "ds_legacy/materials/layouts";
import { useTranslations } from "translations";
import Translations from "translations/types";

import { margin, padding } from "ds_legacy/materials/metrics";
import {
  Body,
  FONT_SIZE_14,
  FONT_SIZE_18,
  FONT_WEIGHT_BOLD,
  Subheading,
} from "ds_legacy/materials/typography";
import {
  Footnote,
  FootnoteDescription,
  FootnoteLabel,
  GAP,
  SectionRow,
  SelectWithLabel,
  TextInputWithLabel,
} from "dsl/ecosystems/PatientForms/components";
import { ICD_CODE_CHARACTER_LIMIT } from "dsl/ecosystems/PatientForms/RehabForms/utils";

const FOOTNOTE: Footnote = {
  superText: 1,
  description: (translations: Translations) =>
    translations.patientForms.medicalForm.sectionTwo.footnoteOne,
  label: (translations: Translations) =>
    translations.patientForms.medicalForm.sectionTwo.icdCode,
};

const DiagnosisInputFields = ({ priority }: { priority: number }) => {
  const translations = useTranslations();

  const CAUSE_OPTIONS: Record<number, string> = {
    0: translations.patientForms.medicalForm.sectionTwo.optionOne,
    1: translations.patientForms.medicalForm.sectionTwo.optionTwo,
    2: translations.patientForms.medicalForm.sectionTwo.optionThree,
    3: translations.patientForms.medicalForm.sectionTwo.optionFour,
    4: translations.patientForms.medicalForm.sectionTwo.optionFive,
    5: translations.patientForms.medicalForm.sectionTwo.optionSix,
  };

  const selectOptions: SelectOption[] = Object.entries(CAUSE_OPTIONS).map(
    ([key, label]) => ({
      id: key,
      label: label,
      value: key,
    }),
  );

  return (
    <SectionRow
      customPadding={padding(2, 0, 0)}
      key={priority}
      style={{ flexWrap: "nowrap" }}
    >
      <Body as="p" margin={margin(0, 0, 1)} style={{ alignSelf: "center" }}>
        {priority}
      </Body>
      <TextInputWithLabel
        bold
        elementName={`icd_code_${priority}_diagnosis`}
        label={
          translations.patientForms.medicalForm.sectionTwo.diagnosisDescription
        }
        labelWrapperSx={{
          width: "40%",
          minWidth: undefined,
        }}
      />
      <TextInputWithLabel
        bold
        characterLimit={ICD_CODE_CHARACTER_LIMIT}
        elementName={`icd_code_${priority}_code`}
        label={<FootnoteLabel footnote={FOOTNOTE} />}
        labelWrapperSx={{
          width: "20%",
          minWidth: undefined,
        }}
      />
      <SelectWithLabel
        bold
        elementName={`icd_code_${priority}_reason`}
        label={translations.patientForms.medicalForm.sectionTwo.cause}
        options={selectOptions}
        wrapperSx={{ width: "40%" }}
        inputSx={{ width: "100%", fontSize: FONT_SIZE_14, minWidth: undefined }}
        formHelperTextSx={{ display: "none" }}
      />
    </SectionRow>
  );
};

export const FunctionDiagnosis = () => {
  const translations = useTranslations();

  return (
    <>
      <Subheading
        as="h5"
        margin={margin(0, 0, 0.5, 0.5)}
        style={{
          fontSize: FONT_SIZE_18,
          fontWeight: FONT_WEIGHT_BOLD,
        }}
      >
        {translations.patientForms.medicalForm.sectionTwo.functionDiagnoses}
      </Subheading>
      {Object.entries(FUNCTION_DIAGNOSIS_PRIORITIES).map(([_, value]) => (
        <DiagnosisInputFields priority={value} key={value} />
      ))}
      <VerticalLayout
        style={{
          padding: padding(GAP, 0, 0),
          boxSizing: "border-box",
        }}
      >
        <FootnoteDescription footnote={FOOTNOTE} />
      </VerticalLayout>
    </>
  );
};
