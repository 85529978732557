import { SxProps } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { GENDER_FEMALE, GENDER_MALE, GENDER_OTHER } from "core/consts";
import RadioGroupV2, { RadioOptionV2 } from "ds_legacy/components/RadioGroupV2";
import { RADIO_WHITE_BACKGROUND } from "ds_legacy/materials/colors";
import { margin, padding, sizing } from "ds_legacy/materials/metrics";
import {
  FONT_SIZE_18,
  FONT_WEIGHT_BOLD,
  Subheading,
} from "ds_legacy/materials/typography";
import {
  DatePickerWithLabel,
  LARGE_INPUT_WIDTH,
  SMALL_INPUT_MIN_WIDTH,
  SectionRow,
  TextInputWithLabel,
} from "dsl/ecosystems/PatientForms/components";
import { useTranslations } from "translations";

export const InsuredPersonDetails = () => {
  const translations = useTranslations();

  const options: RadioOptionV2[] = [
    {
      id: GENDER_MALE,
      value: GENDER_MALE,
      label: translations.patientForms.medicalForm.sectionOne.genderMale,
    },
    {
      id: GENDER_FEMALE,
      value: GENDER_FEMALE,
      label: translations.patientForms.medicalForm.sectionOne.genderFemale,
    },
    {
      id: GENDER_OTHER,
      value: GENDER_OTHER,
      label: translations.patientForms.medicalForm.sectionOne.genderDiverse,
    },
  ];

  return (
    <>
      <Subheading
        as="h5"
        margin={margin(0, 0, 0.5, 0.5)}
        style={{
          fontSize: FONT_SIZE_18,
          fontWeight: FONT_WEIGHT_BOLD,
        }}
      >
        {
          translations.patientForms.medicalForm.sectionOne
            .informationInsuredPerson
        }
      </Subheading>
      <RadioGroupV2
        elementName="gender"
        label={
          translations.patientForms.medicalForm.sectionOne.accessibilityGender
        }
        options={options}
        formControlLabelSx={{ paddingBottom: sizing(0.5), marginLeft: 0 }}
        formLabelSx={visuallyHidden as SxProps}
        radioGroupSx={{ flexDirection: "row", paddingTop: sizing(1) }}
        radioSx={RADIO_WHITE_BACKGROUND}
      />
      <SectionRow customPadding={padding(1, 0, 0)}>
        <TextInputWithLabel
          bold
          label={translations.patientForms.medicalForm.sectionOne.lastName}
          large
          elementName="patient_last_name"
        />
        <TextInputWithLabel
          bold
          label={translations.patientForms.medicalForm.sectionOne.firstName}
          large
          elementName="patient_first_name"
        />
      </SectionRow>
      <SectionRow>
        <TextInputWithLabel
          bold
          label={
            translations.patientForms.generalForm.insuredPerson.insuranceNumber
          }
          large
          elementName="insurance_number"
        />
        <DatePickerWithLabel
          bold
          label={
            translations.patientForms.generalForm.insuredPerson.dateOfBirth
          }
          elementName="patient_birthdate"
          inputSx={{
            width: "100%",
            minWidth: SMALL_INPUT_MIN_WIDTH,
          }}
          wrapperSx={{ width: LARGE_INPUT_WIDTH }}
        />
      </SectionRow>
    </>
  );
};
